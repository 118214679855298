import React from 'react';
import './Testimonials.scss';
import Card from './Card';
import ClubSvg from '../../../../images/svg/Club.inline.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';
import { IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
interface Props {
  lang?: 'eng' | 'hindi';
  style?: React.CSSProperties;
  titleEng?: string;
  titleHindi?: string;
}
export interface ITestimonials {
  name: string;
  position?: string;
  image: IGatsbyImageData;
  location: string;
  prizewon: string;
  date: string;
  review: string;
}

const TestimonialsContent: React.FC<Props> = ({ lang = 'eng', style, titleEng, titleHindi }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const defaultTitleEng = 'Trusted By Millions';
  const defaultTitleHindi = 'लाखों लोगों का भरोसा';
  const titleToUse = lang === 'eng' ? titleEng || defaultTitleEng : titleHindi || defaultTitleHindi;

  const data = useStaticQuery(graphql`
    query {
      user1: file(relativePath: { eq: "index/common/Testimonials/user1.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      user2: file(relativePath: { eq: "index/common/Testimonials/user2.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      user3: file(relativePath: { eq: "index/common/Testimonials/user3.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
      user4: file(relativePath: { eq: "index/common/Testimonials/user4.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
    }
  `);

  const userData: ITestimonials[] = [
    {
      date: '10 Feb 2023',
      image: data.user4.childImageSharp.gatsbyImageData,
      prizewon: '133872288',
      location: 'Mumbai, Maharastra',
      name: 'Mohd Ashfaque',
      review:
        'Playing at GetMega is like playing with your friends, but with the thrill of winning real prizes.',
    },
    {
      date: '02 Jan 2019',
      image: data.user1.childImageSharp.gatsbyImageData,
      prizewon: '210639',
      location: 'Amravati, Gujarat',
      name: 'Nilppa manta',
      position: 'Tailor',
      review:
        'Playing at GetMega is like playing with your friends, but with the thrill of winning real prizes.',
    },
    {
      date: '10 Mar 2022',
      image: data.user2.childImageSharp.gatsbyImageData,
      prizewon: '49947',
      location: 'Champa, Chhattisgarh',
      name: 'Bhavani sahu',
      position: 'Hardware Shop Owner',
      review:
        "GetMega makes my evenings fun and educational - win or lose, it's always a good time.",
    },
    {
      date: '05 Apr 2020',
      image: data.user3.childImageSharp.gatsbyImageData,
      prizewon: '80727',
      location: 'Mumbai, Maharashtra',
      name: 'Darshana machhi',
      position: 'Farmer',
      review:
        'I love the variety of games on GetMega; from high stakes to low stakes, everything can be found here in one place.',
    },
  ];

  const userDataHindi: ITestimonials[] = [
    {
      date: '10 Feb 2023',
      image: data.user4.childImageSharp.gatsbyImageData,
      prizewon: '133872288',
      location: 'मुंबई, महाराष्ट्र',
      name: 'मोहम्मद असफाक,',
      review:
        'GetMega पर खेलना ऐसा है जैसे दोस्तों के साथ खेलना, लेकिन असली इनाम जीतने के रोमांच के साथ।',
    },
    {
      date: '02 Jan 2019',
      image: data.user1.childImageSharp.gatsbyImageData,
      prizewon: '210639',
      location: 'अमरावती, गुजरात',
      name: 'निलप्पा मांटा, दर्जी',
      position: 'Tailor',
      review:
        'GetMega पर खेलना ऐसा है जैसे दोस्तों के साथ खेलना, लेकिन असली इनाम जीतने के रोमांच के साथ।',
    },
    {
      date: '10 Mar 2022',
      image: data.user2.childImageSharp.gatsbyImageData,
      prizewon: '49947',
      location: 'चम्पा, छत्तीसगढ़',
      name: 'भवानी साहू',
      position: 'हार्डवेयर दुकान मालिक',
      review:
        'गेटमेगा मेरी शाम को मजेदार और शिक्षाप्रद बनाता है - जीत हो या हार, यह हमेशा अच्छा समय होता है।',
    },
    {
      date: '05 Apr 2020',
      image: data.user3.childImageSharp.gatsbyImageData,
      prizewon: '80727',
      location: 'मुंबई, महाराष्ट्र',
      name: 'दर्शना मछी',
      position: 'किसान',
      review:
        'मुझे GetMega पर गेम्स की विविधता बहुत पसंद है; यहाँ हाई स्टेक्स से लेकर लो स्टेक्स तक, सब कुछ एक ही जगह मिल जाता है।',
    },
  ];

  const contentToUse = lang === 'eng' ? userData : userDataHindi;

  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <div className={'arrow arrow-right'} onClick={onClick}>
        <StaticImage
          src="../../../../images/svg/chevron-right.png"
          alt="chevron-right"
          loading="eager"
        />
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <div className={'arrow arrow-left'} onClick={onClick}>
        <StaticImage
          src="../../../../images/svg/chevron-left.png"
          alt="chevron-right"
          loading="eager"
        />
      </div>
    );
  }

  const settings: Settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    beforeChange: (_, next) => {
      setCurrentSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div id="testimoniol-container-d2" style={style}>
      <div className="heading-container-testmonials">
        <span className="svg-icon">
          <img src={ClubSvg} alt="svg" />
        </span>
        <h2>{titleToUse}</h2>
        <span className="svg-icon">
          <img src={ClubSvg} alt="svg" />
        </span>
      </div>
      <div className="card-container">
        <Slider {...settings}>
          {contentToUse.map((el: ITestimonials, idx: number) => (
            <Card
              lang={lang}
              {...el}
              key={el.name}
              className={idx === currentSlide ? 'slide active' : 'other-slide'}
            />
          ))}
        </Slider>
      </div>
      <p className="view-all-redirect">
        <Link to="/testimonials/">View All</Link>
      </p>
    </div>
  );
};

export default TestimonialsContent;
