import React from 'react'
import './Avatar.scss'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'

interface IAvatar {
  name: string
  image: IGatsbyImageData
  position?: string
  location: string
}

const Avatar = ({ image, name, position, location }: IAvatar) => {
  return (
    <div id="avatar-container">
      <div className="img-container">
        <GatsbyImage
          loading="eager"
          className="avatar-image"
          image={image}
          alt={name}
        />
      </div>
      <div className="person-details">
        <p className="person-name">{name}</p>
        <p className="person-position">{position}</p>
        <p className="person-location">{location}</p>
      </div>
    </div>
  )
}

export default Avatar
