import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TestimonailsContent from '../components/Testimonials/Testimonials'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { TESTIMONIALS as TESTIMONIALS_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'
import TestimonialsContent from '../components/Design2.0/common/Testimonials/Testimonials'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Testimonials',
    url: TESTIMONIALS_LINK,
  },
]

const Testimonials: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Testimonials: Read User Testimonials on Mega App & Gameplay"
        description="Read about what our users have to say about Mega gaming experience, gameplay, rewards, winnings, and more. Click to submit your testimonial as well!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
      />
      {/* <TestimonailsContent /> */}
      <TestimonialsContent />
    </Layout>
  )
}

export default Testimonials
