import React from 'react'
import './Card.scss'
import Avatar from '../Avatar/Avatar'
import { ITestimonials } from './Testimonials'

interface ICard extends ITestimonials {
  className?: string
  lang?: 'eng' | 'hindi'
}

const Card = ({
  date,
  image,
  location,
  name,
  position,
  prizewon,
  review,
  className,
  lang = 'eng',
}: ICard) => {
  const convertToINR = (prizewon: string) => {
    const prizewonNum = Number(prizewon)
    return prizewonNum.toLocaleString('en-IN')
  }

  return (
    <div id="testimonials-card" className={className}>
      <Avatar
        image={image}
        name={name}
        position={position}
        location={location}
      />
      <p className="prize">
        {lang === 'eng' ? 'Prize Won' : 'जीता इनाम'}: ₹{convertToINR(prizewon)}
      </p>

      <p className="review">{review}</p>
      <p className="date">{date}</p>
    </div>
  )
}

export default Card
